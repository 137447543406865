@charset "UTF-8";

/******************************
-------2021.12.23 JSL Publishing-------
------------- RESET--------------
*******************************/

body, th, td, input, select, textarea, button, a {
    font-family: 'Pretendard', "Malgun Gothic", "맑은 고딕", "dotum", "돋움", sans-serif;
    color: #5A5A77;
    line-height: 1.09;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-stretch: normal;
    font-style: normal;
}

html, body{
    -webkit-text-size-adjust : none;  /* 크롬, 사파리, 오페라 신버전 */
    -ms-text-size-adjust : none;  /* IE */
    -moz-text-size-adjust : none;  /* 파이어폭스 */
    -o-text-size-adjust : none;  /* 오페라 구버전 */
}

* {
    font-size-adjust: none;
    -webkit-font-size-adjust: none;
    -moz-font-size-adjust: none;
    -o-font-size-adjust: none;
}

::-webkit-input-placeholder {
    color: #d4d4d4;
}

:-moz-placeholder {
    color: #d4d4d4;
}

::-moz-placeholder {
    color: #d4d4d4;
}

:-ms-input-placeholder {
    color: #d4d4d4;
}

caption {
    display: none;
}

html, body {
    position: relative;
    overflow: auto;

}

label {
    margin: 0;
    display: block;
}

.close, .close:hover {
    filter: none;
    opacity: 1;
}

/* body{
	overflow:hidden;
} */

body, div,
dl, dt, dd, ul, ol, li,
h1, h2, h3, h4, h5, h6,
pre, code, form, fieldset, legend, textarea, p, blockquote,
th, td, input, select, textarea, button,
header, nav, menu, section, article {
    margin: 0;
    padding: 0
}


fieldset, img {
    border: 0 none
}

dl, ul, ol, menu, li {
    list-style: none;
    vertical-align: middle
}

blockquote, q {
    quotes: none
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
}

input, textarea {
    box-sizing: border-box;
    padding: 0 10px;
}

input, select, textarea, button {
    vertical-align: middle;
    outline: none;
}

input[type='text'], input[type='password'], input[type='submit'], input[type='search'], input[type='password'] {
    -webkit-appearance: none;
}

input[type='text'], input[type='password'], select, textarea, input[type='email'], input[type='number'], input[type='search'] {
    /* border:1px solid #cccccc; */
    height: 37px;
    border: none;
    outline: none;
    background-color: transparent;
}

textarea {
    box-sizing: border-box;
    padding: 15px;
    resize: none;
}

button, input[type='button'], input[type='submit'], input[type='reset'], input[type='file'] {
    -webkit-appearance: none;
    /* border-radius:0 */
}

input[type='button'] {
    -webkit-appearance: none;
    border: 0;
}

input[type='search']:-webkit-search-cancel-button {
    -webkit-appearance: none
}

input[type='radio'], input[type='checkbox'] {
    border: none !important
}

button {
    border: 0 none;
    background-color: transparent;
    cursor: pointer
}

a, a:link, a:visited, a:hover, a:active {
    color: inherit;
    text-decoration: none;
}

address, caption, cite, code, dfn, em, var {
    font-style: normal;
    font-weight: normal
}

table {
    width: 100%;
    border-collapse: collapse;
}

img {
    vertical-align: middle;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}

/* Remove excess padding and border in Firefox 4+ */
/* &::-moz-focus-inner {
    border: 0;
    padding: 0;
} */
