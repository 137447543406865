// theme.sizes... 수치와 통일 필수, 변경 금지
$headerHeight: 75px;
$pageMarginTop: 50px;

.page-info{
    display:flex;
    flex-direction:column;
    height:100%;
    box-sizing: border-box;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: -50px;
    //@media (max-width: 1269px){
    //    .page-wrapper {
    //        height: 56.25vw;
    //        min-height: auto;
    //    }
    //}
}

//@media (max-width: 768px){
//    .page-info{
//        flex-direction:column;
//        padding-top:75px;
//        box-sizing:border-box;
//    }
//}

.line-gradient-bg {
    //background:linear-gradient();
}

.bg-point-color{
    background-color:#1210C9;
}

.mobile-page-info{
    display:flex;
    flex-direction:row;
    width:100%;
    box-sizing: border-box;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: $headerHeight;
}

.border-left{
    border-left:1px solid rgba(255,255,255,0.1);
    box-sizing:border-box;
}

div.border-light,.border-light{
    border-color:rgba(255,255,255,0.1);
}

.border-dark{
    border:1px solid rgba(90,90,119,0.08);
    border-width:0 1px;
}

.border-tb-dark{
    border:1px solid rgba(90,90,119,0.08);
    border-width:1px 0;
    padding-bottom:50px;
}

.contents-wrapper{
    .border-left{
        border-color:rgba(90,90,119,0.08);
    }
}

.ir-content {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
}


/*Mobile*/
.info-wrapper{
    box-sizing:border-box;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding:50px 0;
    h2{
        margin-bottom:0;
        font-size: 1.75rem;
        text-align: center;
    }
    .border-light,.border-dark{
        padding:50px 0;
        box-sizing:border-box;
        text-align: center;
    }
}


// const colors = {
//     landingBackgroundColor: "#00002D",
//     pointColor: "#1210C9",
//     bgColor: "rgba(250,251,255,1)",
//     dFontColor: "#B8B8C6",
//     fontColor: "#5A5A77",
//     borderColor: "rgba(255,255,255,0.1)",
//     lbgBorderColor: "rgba(90,90,119,0.08)",
//     footerUntilColor: "rgba(184,184,198, 1)"
//   };

// const sizes = {
//     headerHeight: "75px",
//     pageMarginTop: "50px"
//   }